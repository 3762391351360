var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      on: {
        click: function ($event) {
          return _vm.redirectBack()
        },
      },
    },
    [
      _c("img", {
        class: {
          "border-colorKitchen": _vm.type === "kitchen",
          "border-colorFurniture": _vm.type === "furniture",
        },
        attrs: {
          src: `${_vm.baseurl}assets/Icon-Bissig-Close-${_vm.type}.svg`,
          width: "48px",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }