<template>
  <div class="bg-color2 h-full">
    <vue-slick-carousel
      ref="homeContent"
      :rows="1"
      :infinite="true"
      :arrows="false"
      :autoplay="true"
      :autoplaySpeed="5000"
      :draggable="true"
      :touchThreshold="100"
      :edge-friction="0.1"
      class="h-full -z-0"
      @swipe="swipeNextSlide"
    >
      <div
        class="relative flex h-full"
        v-for="(entry, index) in entries"
        :key="index"
      >
        <div class="absolute h-full opacity-60">
          <img :src="entry.url" />
        </div>
        <div class="relative left-80 l:left-52 top-[18.5rem] l:top-[20%] mr-10">
          <div class="mb-12 w-1/4 l:w-1/3">
            <p class="text-color1 helvetica_neue-bold-f1">
              {{
                typeText(entry).slogan
              }}
            </p>
          </div>
          <div class="ml-24 w-1/4 l:w-1/3">
            <p class="text-color1 helvetica_neue-f3">
              {{ typeText(entry).smallText }}
            </p>
          </div>
          <a :href="entry.detailUrl" class="flex justify-content ml-7/24 l:mt-4 mt-8 ">
            <img
              :src="`${baseurl}assets/Icon-Pfeil_rechts-Menu.svg`"
              width="48px"
            />
            <p class="ml-4 self-center text-color1 helvetica_neue-bold-f3 whitespace-nowrap">
              {{ entry.category }} entdecken
            </p>
          </a>
        </div>
        <div class="absolute top-[67%] left-[40%] z-50">
        </div>
      </div>
    </vue-slick-carousel>
          <event-notice :event="event" />
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
import vueSlickCarousel from 'vue-slick-carousel';
import sidebar from '../mainSidebar/mainSidebar.vue';
import bissigLogo from '../bissigLogo.vue';
import eventNotice from './eventNotice.vue';
export default {
  name: 'homeCarousel',
  props: {
    entries: Array,
    type: Array,
    event:{
      type:Array|Object
    }
  },
  components: {
    vueSlickCarousel,
    sidebar,
    bissigLogo,
    eventNotice
  },
  data() {
    return {
      baseurl: BASEURL,
    };
  },
  methods: {
    swipeNextSlide() {
      this.$refs.homeContent.pause();
      this.$refs.homeContent.play();
    },
    typeText(entry){
      if(entry.category == "Küche"){
        return this.type[0]
      }
      else{
        return this.type[1]
      }
    },
  },
  computed:{

  }
};
</script>
<style lang="">
</style>