<template>
  <div
    class="flex flex-col flex-wrap bg-colorMenu w-full h-screen z-10"
    v-if="enabled"
  >
    <div
      class="ml-[51px] first:mt-40 m-8"
      v-for="(item, index) in navdata"
      :key="index"
    >
      <a
        class="z-10 text-color1 helvetica_neue-bold-f1 text-[35px]"
        :href="baseurl + item.navigationUrl"
        >{{ item.name.toUpperCase() }}</a
      >
    </div>
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    enabled: Boolean,
    navdata: Array,
  },
  data() {
    return {
      baseurl: BASEURL
    };
  }
}
</script>
<style lang="">
</style>