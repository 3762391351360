var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      attrs: {
        src: `${_vm.baseurl}assets/Icon-Bissig-Heart-${_vm.type}_border.svg`,
        width: "48px",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }