<template>
 <div v-if="filter.subcategories.length != 0">
      <div>
        <button @click="enabled = !enabled" :disabled="disable()" class="flex w-full mr-4 disabled:cursor-not-allowed items-center">
          <p class="filterGroupTitle">{{ filter.name }} ({{filter.count}})</p>
          <p class="filterPlusMinus" v-if="!enabled">
            +
          </p>
          <p class="filterPlusMinus" v-if="enabled">
            -
          </p>
        </button>
      </div>
      <hr class="subline" />
      <div v-if="enabled">
        <ul>
          <li
            v-for="(subcategory, index) in filter.subcategories"
            class="flex flex-row space-x-3 first:mt-2"
            :key="index">
            <label :for="subcategory.name" class="flex flex-row">
              <input type="checkbox"
               :class="type" 
               :disabled="disableSubCat(subcategory.count)" 
               @change="$emit('checked',subcategory.name)" 
               :id="subcategory.name" :name="subcategory.name"  
               :checked="checkedFilter.includes(subcategory.name)" 
              />
              <p class="text-color1 helvetica_neue-base ml-2">{{ subcategory.name }} ({{subcategory.count}})</p>
            </label>
          </li>
        </ul>
      </div>
    </div>
</template>
<script lang="ts">
export default {
  props: {
    filter: Object,
    checkedFilter: Array,
    type: String
  },
  data() {
    return {
      enabled: false
    };
  },
  methods: {
    checkBefore( name: String ) {
      for ( const filter in this.checkedFilter ) {
        if ( Object.prototype.hasOwnProperty.call( this.checkedFilter, filter ) ) {
          const element = this.checkedFilter[ filter ];
          if ( element === name ) {
            return true;
          }
          else {
            return false;
          }
        }

      }
      return false;
    },
    disable() {
      if ( this.filter.subcategories.length > 0 ) {
        return false;
      }
      else {
        return true;
      }
    },
    disableSubCat( count: Number ) {
      if ( count > 0 ) {
        return false;
      }
      else {
        return true;
      }
    }
  }
}
</script>
<style lang="">
</style>