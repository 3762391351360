<template>
  <div class="flex flex-row w-full">
    <div class="absolute z-20 left-10" v-if="!enabledLeft">
      <bissig-logo :type="type"></bissig-logo>
    </div>
    <div class="w-1/3 absolute z-10">
      <gallery-filter
        class=""
        :checkedFilter="filterList"
        :filters="filters"
        :navdata="navdata"
        :type="type"
        height="bottom"
        :countEntries="countEntries"
        @disable="enabledLeft = !enabledLeft"
        @checked="filterListAdd"
        @clearFilter="clearFilter"
        @filter="filterEntries"
      />
    </div>
    <div
      class="galleryWrapper"
      :class="{
        'z-20': !enabledLeft && !enabledRight,
        'z-0': enabledLeft || enabledRight,
      }"
    >
      <detail-gallery :entries="filteredEntries" :type="type" />
    </div>
    <div class="w-1/3 ml-auto relative z-10">
      <gallery-filter
        :checkedFilter="filterList"
        :filters="filters"
        :navdata="navdata"
        :type="type"
        height="top"
        :countEntries="countEntries"
        @disable="enabledRight = !enabledRight"
        @checked="filterListAdd"
        @filter="filterEntries"
        @clearFilter="clearFilter"
      />
    </div>
  </div>
</template>
<script lang="ts">
import detailGallery from "./details.vue";
import galleryFilter from "./detailSidebar/galleryFilter.vue";
import bissigLogo from '../bissigLogo.vue';
export default {
  components: {
    galleryFilter,
    detailGallery,
    bissigLogo
  },
  props: {
    propfilters: Array,
    navdata: Array,
    type: String,
    entries: Array,
  },
  data() {
    return {
      filters: structuredClone( this.propfilters ),
      enabledLeft: false,
      enabledRight: false,
      filteredEntries: structuredClone(this.entries),
      tempFilteredEntries: [],
      filterList: [],
    };
  },
  methods: {
    filterListAdd( filter: String ) {
      if ( !this.filterList.find( ( category: String ) => category === filter ) ) {
        this.filterList.push( filter );
      }
      else {
        this.filterList.splice( this.filterList.indexOf( filter ), 1 );
      }
      this.tempFilteredEntries = [];
      for ( const entry in this.entries ) {
        if ( Object.prototype.hasOwnProperty.call( this.entries, entry ) ) {
          const element = this.entries[ entry ];
          const checkFilters = this.checkFilters( element.categories );
          if ( checkFilters.length == this.filterList.length ) {
            if ( this.tempFilteredEntries.find( ( filteredEntry: Object ) => element == filteredEntry ) == null ) {
              this.tempFilteredEntries.push( element );

            }
          }
        }
      };
      this.updateCategories();
      if ( this.filterList.length === 0 ) {
        this.tempFilteredEntries = this.entries;
      }
    },
    filterEntries() {
      this.filteredEntries = [];
      if(this.tempFilteredEntries.length >0){
        this.filteredEntries = structuredClone(this.tempFilteredEntries);
      }
      else{
        this.filteredEntries= structuredClone(this.entries)
      }
    },
    clearFilter() {
      this.filteredEntries = this.entries;
      this.filterList = [];
      this.filters = [];
      this.updateCategories();
      this.filters = this.propfilters;
    },
    checkFilters( subCategories: Object ) {
      let checkedTrue = [];
      for ( const i in subCategories ) {
        if ( Object.prototype.hasOwnProperty.call( subCategories, i ) ) {
          const subCategory = subCategories[ i ];
          for ( const item in this.filterList ) {
            if ( Object.prototype.hasOwnProperty.call( this.filterList, item ) ) {
              const filter = this.filterList[ item ];
              if ( filter === subCategory.title ) {
                checkedTrue.push( 'true' );
              }
            }
          }
        }
      }
      return checkedTrue;
    },
    updateCategories() {

      for ( const i in this.filters ) {
        if ( Object.prototype.hasOwnProperty.call( this.filters, i ) ) {
          const filter = this.filters[ i ];
          let newValues = [];
          for ( const item in filter.subcategories ) {
            if ( Object.prototype.hasOwnProperty.call( filter.subcategories, item ) ) {
              const subCategory = filter.subcategories[ item ];
              subCategory.count = 0;
              for ( const piece in this.tempFilteredEntries ) {
                if ( Object.prototype.hasOwnProperty.call( this.tempFilteredEntries, piece ) ) {
                  const entry = this.tempFilteredEntries[ piece ];
                  if ( entry.categories.find( ( subCat: Object ) => subCategory.name == subCat.title ) ) {
                    subCategory.count++;
                    console.log( subCategory.name, subCategory.count );
                  }
                }
              }
              newValues.push( subCategory.count );
            }
          }
          filter.count = Math.max( ...newValues );
        }
      }
    }
  },
  computed: {
    countEntries() {
      if ( this.tempFilteredEntries != this.entries ) {
        return this.tempFilteredEntries.length;
      }
      else {
        return 0;
      }
    },
  },
}
</script>
<style lang="">
</style>