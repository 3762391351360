<template>
  <div class="overflow-x-scroll gallery">
    <div
      class="grid grid-rows-2 grid-flow-col-dense"
      v-if="entries[0] != null"
    >
      <galleryImage
        class="galleryImage"
        v-for="(item, index) in entries"
        :key="index"
        :entry="item"
      />
    </div>
  </div>
</template>
<script lang="ts">
import galleryImage from './galleryImage.vue';
export default {
  props: {
    entries: Array,
    type: String
  },
  components: { galleryImage },
}
</script>
<style lang="">
</style>