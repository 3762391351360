<template>
  <div class="flex flex-row">
    <div class="absolute z-[25] left-10" v-if="!enabledLeft">
      <bissig-logo :type="type"></bissig-logo>
    </div>
    <div class="absolute h-full w-1/3">
      <carousel-sidebars
        :type="type"
        height="bottom"
        :navdata="navdata"
        @plusSlides="plusSlide"
        @disable="enabledLeft = !enabledLeft"
      ></carousel-sidebars>
    </div>
    <div
      class="carousel"
      :class="{
        'z-20': !enabledLeft && !enabledRight,
        'z-0': enabledLeft || enabledRight,
      }"
    >
      <carousel
        :entry="entry"
        :type="type"
        :sliderIndex="sliderIndex"
        :changeSlide="changeSlide"
      />
    </div>
    <div class="rightCarouselBar">
      <carousel-sidebars
        :type="type"
        height="top"
        :navdata="navdata"
        @plusSlides="plusSlide"
        @disable="enabledRight = !enabledRight"
      ></carousel-sidebars>
    </div>
  </div>
</template>
<script lang="ts">
import MainSidebar from '../mainSidebar/mainSidebar.vue';
import CarouselSidebars from './carouselSidebars.vue';
import carousel from './carousel.vue';
import bissigLogo from '../bissigLogo.vue';
export default {
  components: { MainSidebar, CarouselSidebars, carousel, bissigLogo },
  props: {
    entry: Object,
    navdata: Array,
    type: String,
    destination: String
  },
  data() {
    return {
      sliderIndex: Boolean,
      changeSlide: Boolean,
      enabledLeft: false,
      enabledRight: false
    };
  },
  methods: {
    plusSlide( i: Boolean ) {
      this.sliderIndex = i;
      this.changeSlide = !this.changeSlide;
    },
  },
}
</script>
<style lang="">
</style>