<template>
  <div>
    <img
      :src="`${baseurl}assets/Icon-Bissig-Heart-${type}_border.svg`"
      width="48px"
    />
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    type: String,
  },
  data() {
    return {
      baseurl: BASEURL
    };
  }
};
</script>
