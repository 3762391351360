var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "h-full" },
      [
        _c(
          "vue-slick-carousel",
          {
            ref: "carousel",
            attrs: { arrows: false, touchThreshold: 100, "edge-friction": 0.1 },
          },
          [
            _c("div", {
              staticClass: "pt-16/10 bg-img h-full",
              style: { backgroundImage: `url(${_vm.entry.image})` },
            }),
            _vm._v(" "),
            _vm._l(_vm.entry.images, function (image, index) {
              return _c("div", {
                key: index,
                staticClass: "bg-img pt-16/10",
                style: { backgroundImage: `url(${image})` },
              })
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }