<script lang="ts">
import { defineComponent } from 'vue';
import { BASEURL } from '@libTs/cms.globals';
import { event } from 'jquery';

export default defineComponent({
  name: 'eventNotice',
  props: {
    event:{
      type:Array|Object
    }
  },
  data() {
    return {
      overlayEnabled: false,
      baseurl: BASEURL
    };
  },
  methods: {
    toggleOverlay() {
      this.overlayEnabled = !this.overlayEnabled;
      dispatchEvent(new CustomEvent('eventOverlay', { detail: this.overlayEnabled }, { bubbles: true }));
    }
  }
});
</script>

<template>
  <div v-if="event.eventHinweis ?? event.eventHinweis.length ">
    <div class="absolute text-white bottom-[9%] right-[5%]">
      <div class="relative bg-green text-4xl rounded-full flex justify-center items-center h-72 w-72 shadow-lg  pulse cursor-pointer" @click="toggleOverlay()">
        <div class="text-color1   p-6  text-center " v-html="event.eventHinweis">
          
        </div>
      </div>
    </div>
    <div v-if="overlayEnabled" class="absolute z-[99999999] top-0 left-0 bottom-0 right-0 bg-color1 flex">
      <img :src="event.image" class="my-2/24 ml-auto mr-auto aspect-[16/9]" />
      <!--    <div class="bg-img__16-9 w-full mx-10 my-10" :style="{backgroundImage:`url(${event.image})`}">-->
      <div class=" mr-10 mt-10">
        <img :src="`${baseurl}assets/Icon-Bissig-Close.svg`" width="48px" @click="toggleOverlay()" class="cursor-pointer" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@keyframes scale {
  0% {scale: 1; @apply text-4xl}
  50% {scale:0.9; @apply text-4xl}
  100% {scale: 1; @apply text-4xl}

}
.pulse{
  @apply transition-all;
  animation: scale 6s infinite;
}
</style>