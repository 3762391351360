var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("a", { attrs: { href: _vm.baseurl } }, [
      _c("img", { attrs: { src: _vm.logoType, width: "96px" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }