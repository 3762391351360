<template>
    <button @click="redirectBack()">
      <img :src="`${baseurl}assets/Icon-Bissig-Close-${type}.svg`" width="48px" class="" :class="{
        'border-colorKitchen': type === 'kitchen',
        'border-colorFurniture': type === 'furniture',
      }"/>
    </button>
</template>
<script>
import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    type: String,
  },
  data() {
    return {
      baseurl: BASEURL,
    };
  },
  methods: {
    redirectBack() {
      window.location.replace(`./`);
    },
  },
};
</script>
<style lang="">
</style>