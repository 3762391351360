<template>
<div class="h-screen overflow-y-hidden">
  <div class="z-20 left-10 absolute" v-if="!enabledLeft">
    <bissig-logo class=""></bissig-logo>
  </div>
  <div class="w-1/3 absolute">
    <sidebar :navdata="navdata" height="bottom" @disable="enabledLeft= !enabledLeft"></sidebar>
  </div>  
  <div class="absolute  w-full h-full"
  :class="{
    'z-20': enabledEventOverlay,
    'z-0': !enabledEventOverlay
  }"
  >
    <home-carousel
      :entries="entries"
      :type="type"
      :event="event"
    ></home-carousel>
  </div>
  <div class="w-1/3 ml-auto top-10">
    <sidebar
      :navdata="navdata"
      height="top"
      @disable="enabledRight= !enabledRight"
    ></sidebar>
  </div>
</div>
</template>
<script lang="ts">
import bissigLogo from '../bissigLogo.vue';
import sidebar from '../mainSidebar/mainSidebar.vue';
import homeCarousel from './homeCarousel.vue';
export default {
  name: 'Home',
  components: {
    homeCarousel,
    sidebar,
    bissigLogo,
  },
  props: {
    navdata: Array,
    entries: Array,
    type:Array,
    event:{
      type: Array|Object,
      default: {}
    }
  },
  data() {
    return {
      enabledLeft: false,
      enabledRight: false,
      enabledEventOverlay:false
    };
  },
  mounted(){
    window.addEventListener("eventOverlay", (event)=>{
      console.log(event)
      this.enabledEventOverlay = event.detail
    })
  }
};
</script>
<style lang="">
</style>