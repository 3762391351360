<template>
  <div>
    <div class="h-full">
      <vue-slick-carousel ref="carousel" :arrows="false" :touchThreshold="100"
                          :edge-friction="0.1">
        <div class="pt-16/10 bg-img h-full" :style="{backgroundImage: `url(${entry.image})`}">
          
        </div>
        <div v-for="(image, index) in entry.images" :key="index" class="bg-img pt-16/10" :style="{backgroundImage: `url(${image})`}">
        </div>
      </vue-slick-carousel>
    </div>
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
import VueSlickCarousel from 'vue-slick-carousel';
export default {
  props: {
    entry: Object,
    sliderIndex: Boolean,
    changeSlide: Boolean
  },
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      baseurl: BASEURL,
    };
  },
  methods: {
  },
  watch: {
    changeSlide() {
      if ( this.sliderIndex ) {
        this.$refs.carousel.next();
      }
      else {
        this.$refs.carousel.prev();
      }
    }
  },
}
</script>
<style lang="">
</style>