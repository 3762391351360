var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled
    ? _c(
        "div",
        {
          staticClass:
            "flex flex-col flex-wrap bg-colorMenu w-full h-screen z-10",
        },
        _vm._l(_vm.navdata, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "ml-[51px] first:mt-40 m-8" },
            [
              _c(
                "a",
                {
                  staticClass:
                    "z-10 text-color1 helvetica_neue-bold-f1 text-[35px]",
                  attrs: { href: _vm.baseurl + item.navigationUrl },
                },
                [_vm._v(_vm._s(item.name.toUpperCase()))]
              ),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }