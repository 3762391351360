<template>
  <div class="">
    <a :href="baseurl">
      <img :src="logoType" width="96px" />
    </a>
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    type: String,
  },
  computed: {
    logoType() {
      if ( this.type === 'furniture' || this.type === 'kitchen' ) {
        return this.baseurl + 'assets/Icon-Bissig-Logo-' + this.type + '.svg';
      } else {
        return this.baseurl + 'assets/Icon-Bissig-Logo-menu.svg';
      }
    },
  },
  data() {
    return {
      baseurl: BASEURL
    };
  }
};
</script>
