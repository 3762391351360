var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-row w-full" }, [
    !_vm.enabledLeft
      ? _c(
          "div",
          { staticClass: "absolute z-20 left-10" },
          [_c("bissig-logo", { attrs: { type: _vm.type } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-1/3 absolute z-10" },
      [
        _c("gallery-filter", {
          attrs: {
            checkedFilter: _vm.filterList,
            filters: _vm.filters,
            navdata: _vm.navdata,
            type: _vm.type,
            height: "bottom",
            countEntries: _vm.countEntries,
          },
          on: {
            disable: function ($event) {
              _vm.enabledLeft = !_vm.enabledLeft
            },
            checked: _vm.filterListAdd,
            clearFilter: _vm.clearFilter,
            filter: _vm.filterEntries,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "galleryWrapper",
        class: {
          "z-20": !_vm.enabledLeft && !_vm.enabledRight,
          "z-0": _vm.enabledLeft || _vm.enabledRight,
        },
      },
      [
        _c("detail-gallery", {
          attrs: { entries: _vm.filteredEntries, type: _vm.type },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-1/3 ml-auto relative z-10" },
      [
        _c("gallery-filter", {
          attrs: {
            checkedFilter: _vm.filterList,
            filters: _vm.filters,
            navdata: _vm.navdata,
            type: _vm.type,
            height: "top",
            countEntries: _vm.countEntries,
          },
          on: {
            disable: function ($event) {
              _vm.enabledRight = !_vm.enabledRight
            },
            checked: _vm.filterListAdd,
            filter: _vm.filterEntries,
            clearFilter: _vm.clearFilter,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }