var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-x-scroll gallery" }, [
    _vm.entries[0] != null
      ? _c(
          "div",
          { staticClass: "grid grid-rows-2 grid-flow-col-dense" },
          _vm._l(_vm.entries, function (item, index) {
            return _c("galleryImage", {
              key: index,
              staticClass: "galleryImage",
              attrs: { entry: item },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }