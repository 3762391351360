<template>
  <div>
    <a :href="entry.url">
      <div
        class="bg-img__16-9"
        :style="{ backgroundImage: `url(${entry.image})` }"
      ></div>
    </a>
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    entry: Object
  },
  data() {
    return {
      baseurl: BASEURL
    };
  }
}
</script>
<style lang="">
</style>