<template>
  <div>
    <div>
      <button class="" v-if="!enabled" @click="$emit('changeState')">
        <img
          v-if="type != null"
          :src="`${baseurl}assets/Icon-Menu-${type}.svg`"
          width="48px"
        />
        <img v-else :src="`${baseurl}assets/Icon-Menu-Grau.svg`" width="48px" />
      </button>
      <button v-if="enabled" @click="$emit('changeState')">
        <img
          v-if="height === 'bottom'"
          :src="`${baseurl}assets/Icon-Pfeil_links-Menu.svg`"
          width="48px"
        />
        <img
          v-else
          :src="`${baseurl}assets/Icon-Pfeil_rechts-Menu.svg`"
          width="48px"
        />
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
export default {
  props: {
    enabled: Boolean,
    height: String,
    type: String
  },
  data() {
    return {
      baseurl: BASEURL
    };
  },
};
</script>
<style lang="">
</style>