<template>
  <div class="flex flex-row h-screen">
    <main-sidebar-menu
      :enabled="enabled"
      :navdata="navdata"
    ></main-sidebar-menu>
    <main-side-bar-button
      class="absolute mr-auto"
      :class="{
        'right-10 top-10': height === 'top',
        'left-10 bottom-10': height === 'bottom',
        'z-20': enabled && height === 'top' || height=='bottom'
      }"
      :enabled="enabled"
      @changeState="
        enabled = !enabled;
        $emit('disable');
      "
      :height="height"
    ></main-side-bar-button>
  </div>
</template>

<script lang="ts">
import MainSidebarMenu from "./mainSidebarMenu.vue";
import MainSideBarButton from "./mainSideBarButton.vue";
import { BASEURL } from '@libTs/cms.globals';
export default {
  name: "sidebar",
  methods: {
    logTable() {
      console.log( this.navData );
    },
  },
  data() {
    return {
      enabled: false,
      baseurl: BASEURL
    };
  },
  props: {
    navdata: Array,
    height: String,
    type: String
  },
  components: { MainSidebarMenu, MainSideBarButton },
};
</script>
<style lang="">
</style>