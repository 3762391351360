var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.event.eventHinweis ?? _vm.event.eventHinweis.length
    ? _c("div", [
        _c(
          "div",
          { staticClass: "absolute text-white bottom-[9%] right-[5%]" },
          [
            _c(
              "div",
              {
                staticClass:
                  "relative bg-green text-4xl rounded-full flex justify-center items-center h-72 w-72 shadow-lg pulse cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.toggleOverlay()
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "text-color1 p-6 text-center",
                  domProps: { innerHTML: _vm._s(_vm.event.eventHinweis) },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.overlayEnabled
          ? _c(
              "div",
              {
                staticClass:
                  "absolute z-[99999999] top-0 left-0 bottom-0 right-0 bg-color1 flex",
              },
              [
                _c("img", {
                  staticClass: "my-2/24 ml-auto mr-auto aspect-[16/9]",
                  attrs: { src: _vm.event.image },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mr-10 mt-10" }, [
                  _c("img", {
                    staticClass: "cursor-pointer",
                    attrs: {
                      src: `${_vm.baseurl}assets/Icon-Bissig-Close.svg`,
                      width: "48px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleOverlay()
                      },
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }