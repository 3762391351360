<template>
  <div class="flex flex-row h-screen">
    <main-sidebar-menu
      :enabled="enabledMainMenu"
      :navdata="navdata"
    ></main-sidebar-menu>
    <div
      class="flex flex-col flex-wrap w-full"
      :class="{
        'bg-colorKitchen': type === 'kitchen',
        'bg-colorFurniture': type === 'furniture',
      }"
      v-if="enabled"
    >
      <div class="ml-auto mt-28 w-3/4">
        <filter-category
          :checkedFilter="checkedFilter"
          :filters="filters"
          :height="height"
          :countEntries="countEntries"
          :type="type"
          @checked="
            (n) => {
              $emit('checked', n);
            }
          "
          @filter="
            $emit('filter');
            enabled = !enabled;
            $emit('disable');
          "
        ></filter-category>
      </div>
    </div>
    <div
      class="fixed z-20"
      :class="{
        'right-10 top-10': height === 'top',
        'left-10 bottom-10': height === 'bottom',
      }"
    >
      <div v-if="enabled" class="flex flex-row space-x-3">
        <button
          class="
             border-color1
            h-12
            w-12
            flex
            justify-center
            items-center
          "
          v-if="height === 'bottom'"
          @click="
            enabled = !enabled;
            $emit('disable');
          "
        >
          <img :src="`${baseurl}assets/Icon-X-Close-Weiss.svg`"  />
        </button>
        <button
          class="border-[3px] border-color1 h-12"
          @click="
            $emit('clearFilter');
            $emit('disable');
            enabled = !enabled;
          "
        >
          <p class="next_button">Filter zurücksetzen</p>
        </button>
        <button
          class="
            border-[0.063rem] border-color1
            h-12
            w-12
            flex
            justify-center
            items-center
          "
          v-if="height === 'top'"
          @click="
            enabled = !enabled;
            $emit('disable');
          "
        >
          <img
            class=""
            :src="`${baseurl}assets/Icon-X-Close-Weiss.svg`"
          />
        </button>
      </div>
      <div v-if="!enabled" class="flex flex-row space-x-3 justify-center">
        <div v-if="!enabledMainMenu" class="space-x-3">
          <back-button :type="type"></back-button>
          <button
            @click="
              enabled = !enabled;
              $emit('disable');
            "
          >
            <img
              :src="`${baseurl}assets/Icon-Filter-${type}.svg`"
              width="48px"
            />
          </button>
        </div>
        <main-side-bar-button
          :enabled="enabledMainMenu"
          @changeState="
            enabledMainMenu = !enabledMainMenu;
            $emit('disable');
          "
          :height="height"
          :type="type"
        ></main-side-bar-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import MainSidebarMenu from '../../mainSidebar/mainSidebarMenu.vue';
import MainSideBarButton from '../../mainSidebar/mainSideBarButton.vue';
import FilterCategory from './FilterCategory.vue';
import { BASEURL } from '@libTs/cms.globals';
import BackButton from '../../backButton.vue';
export default {
  name: "galleryFilter",
  props: {
    filters: Array,
    type: String,
    height: String,
    navdata: Array,
    checkedFilter: Array,
    countEntries: Number
  },
  data() {
    return {
      enabled: false,
      enabledMainMenu: false,
      baseurl: BASEURL
    };
  },
  components: { MainSidebarMenu, MainSideBarButton, FilterCategory, BackButton }
};
</script>
<style>
</style>