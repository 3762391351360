<template>
  <div class="flex flex-row h-screen">
    <main-sidebar-menu
      :enabled="enabled"
      :navdata="navdata"
    ></main-sidebar-menu>
    <div
      class="fixed z-30"
      :class="{
        ' right-10 top-10': height === 'top',
        ' left-10 bottom-10': height === 'bottom',
      }"
    >
      <div
        v-if="!enabled"
        class="flex-row flex justify-center space-x-2 relative z-30"
      >
        <!-- <like-example :type="type" /> -->
        <div>
          <button @click="redirectBack()">
            <img
              :src="`${baseurl}assets/Icon-Bissig-Close-${type}.svg`"
              width="48px"
              class=""
              :class="{
                'border-colorKitchen': type === 'kitchen',
                'border-colorFurniture': type === 'furniture',
              }"
            />
          </button>
        </div>
        <main-sidebar-button
          :height="height"
          @changeState="
            enabled = !enabled;
            $emit('disable');
          "
          :type="type"
        ></main-sidebar-button>
      </div>
      <div v-else>
        <button
          @click="
            enabled = !enabled;
            $emit('disable');
          "
        >
          <img
            :src="`${baseurl}assets/Icon-Pfeil_rechts-Menu.svg`"
            width="48px"
          />
        </button>
      </div>
    </div>
    <div
      v-if="height === 'top' && !enabled"
      class="sales flex flex-col space-y-3 z-40"
    >
      <button @click="$emit('plusSlides', true)">
        <img
          :src="`${baseurl}assets/Icon-Pfeil_rechts-${type}.svg`"
          width="48px"
        />
      </button>
      <button @click="$emit('plusSlides', false)">
        <img
          :src="`${baseurl}assets/Icon-Pfeil_links-${type}.svg`"
          width="48px"
        />
      </button>
    </div>
    <div
      v-if="height === 'bottom' && !enabled"
      class="customer flex flex-col space-y-3 z-40"
    >
      <button @click="$emit('plusSlides', true)">
        <img
          :src="`${baseurl}assets/Icon-Pfeil_rechts-${type}.svg`"
          width="48px"
        />
      </button>
      <button @click="$emit('plusSlides', false)">
        <img
          :src="`${baseurl}assets/Icon-Pfeil_links-${type}.svg`"
          width="48px"
        />
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import mainSidebarButton from '../mainSidebar/mainSideBarButton.vue';
import MainSidebarMenu from '../mainSidebar/mainSidebarMenu.vue';
import likeExample from './likeExample.vue';
import backButton from '../backButton.vue';
import { BASEURL } from '@libTs/cms.globals';
export default {
  components: { mainSidebarButton, MainSidebarMenu, likeExample, backButton },
  props: {
    navdata: Array,
    type: String,
    height: String,
  },
  data() {
    return {
      enabled: false,
      baseurl: BASEURL
    };
  },
  methods: {
    redirectBack() {
      if ( this.type === "kitchen" ) {
        window.location.replace( './produkte/kitchen' );
      }
      else {
        window.location.replace( './produkte/furniture' );
      }
    }
  }
}
</script>
<style lang="">
</style>