var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-row h-screen" },
    [
      _c("main-sidebar-menu", {
        attrs: { enabled: _vm.enabled, navdata: _vm.navdata },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed z-30",
          class: {
            " right-10 top-10": _vm.height === "top",
            " left-10 bottom-10": _vm.height === "bottom",
          },
        },
        [
          !_vm.enabled
            ? _c(
                "div",
                {
                  staticClass:
                    "flex-row flex justify-center space-x-2 relative z-30",
                },
                [
                  _c("div", [
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.redirectBack()
                          },
                        },
                      },
                      [
                        _c("img", {
                          class: {
                            "border-colorKitchen": _vm.type === "kitchen",
                            "border-colorFurniture": _vm.type === "furniture",
                          },
                          attrs: {
                            src: `${_vm.baseurl}assets/Icon-Bissig-Close-${_vm.type}.svg`,
                            width: "48px",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("main-sidebar-button", {
                    attrs: { height: _vm.height, type: _vm.type },
                    on: {
                      changeState: function ($event) {
                        _vm.enabled = !_vm.enabled
                        _vm.$emit("disable")
                      },
                    },
                  }),
                ],
                1
              )
            : _c("div", [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.enabled = !_vm.enabled
                        _vm.$emit("disable")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: `${_vm.baseurl}assets/Icon-Pfeil_rechts-Menu.svg`,
                        width: "48px",
                      },
                    }),
                  ]
                ),
              ]),
        ]
      ),
      _vm._v(" "),
      _vm.height === "top" && !_vm.enabled
        ? _c("div", { staticClass: "sales flex flex-col space-y-3 z-40" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("plusSlides", true)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: `${_vm.baseurl}assets/Icon-Pfeil_rechts-${_vm.type}.svg`,
                    width: "48px",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("plusSlides", false)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: `${_vm.baseurl}assets/Icon-Pfeil_links-${_vm.type}.svg`,
                    width: "48px",
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.height === "bottom" && !_vm.enabled
        ? _c("div", { staticClass: "customer flex flex-col space-y-3 z-40" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("plusSlides", true)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: `${_vm.baseurl}assets/Icon-Pfeil_rechts-${_vm.type}.svg`,
                    width: "48px",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("plusSlides", false)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: `${_vm.baseurl}assets/Icon-Pfeil_links-${_vm.type}.svg`,
                    width: "48px",
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }