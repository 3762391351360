var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-row" }, [
    !_vm.enabledLeft
      ? _c(
          "div",
          { staticClass: "absolute z-[25] left-10" },
          [_c("bissig-logo", { attrs: { type: _vm.type } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "absolute h-full w-1/3" },
      [
        _c("carousel-sidebars", {
          attrs: { type: _vm.type, height: "bottom", navdata: _vm.navdata },
          on: {
            plusSlides: _vm.plusSlide,
            disable: function ($event) {
              _vm.enabledLeft = !_vm.enabledLeft
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "carousel",
        class: {
          "z-20": !_vm.enabledLeft && !_vm.enabledRight,
          "z-0": _vm.enabledLeft || _vm.enabledRight,
        },
      },
      [
        _c("carousel", {
          attrs: {
            entry: _vm.entry,
            type: _vm.type,
            sliderIndex: _vm.sliderIndex,
            changeSlide: _vm.changeSlide,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rightCarouselBar" },
      [
        _c("carousel-sidebars", {
          attrs: { type: _vm.type, height: "top", navdata: _vm.navdata },
          on: {
            plusSlides: _vm.plusSlide,
            disable: function ($event) {
              _vm.enabledRight = !_vm.enabledRight
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }