<template>
  <div>
    <div class="filters" :class="height == 'bottom' ?'ml-[0.7rem]' :''">
      <sub-filters
        v-for="(item, index) in filters"
        :key="index"
        :checkedFilter="checkedFilter"
        :filter="item"
        :type="type"
        @checked="
          (n) => {
            $emit('checked', n);
          }
        "
      ></sub-filters>
    </div>
    <div class="w-3/4 pr-4">
      <button
        class="w-full border-[3.5px] border-color1 p-2"
        @click="$emit('filter')"
      >
        <p class="next_button">Filter Anwenden ({{ countEntries }})</p>
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { BASEURL } from '@libTs/cms.globals';
import subFilters from './subFilters.vue';
export default {
  components: { subFilters },
  props: {
    filters: Array,
    checkedFilter: Array,
    height: String,
    countEntries: Number,
    type: String
  },
  data() {
    return {
      enabled: false,
      baseurl: BASEURL
    };
  }
};
</script>
<style lang="">
</style>