var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-color2 h-full" },
    [
      _c(
        "vue-slick-carousel",
        {
          ref: "homeContent",
          staticClass: "h-full -z-0",
          attrs: {
            rows: 1,
            infinite: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            draggable: true,
            touchThreshold: 100,
            "edge-friction": 0.1,
          },
          on: { swipe: _vm.swipeNextSlide },
        },
        _vm._l(_vm.entries, function (entry, index) {
          return _c(
            "div",
            { key: index, staticClass: "relative flex h-full" },
            [
              _c("div", { staticClass: "absolute h-full opacity-60" }, [
                _c("img", { attrs: { src: entry.url } }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "relative left-80 l:left-52 top-[18.5rem] l:top-[20%] mr-10",
                },
                [
                  _c("div", { staticClass: "mb-12 w-1/4 l:w-1/3" }, [
                    _c(
                      "p",
                      { staticClass: "text-color1 helvetica_neue-bold-f1" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.typeText(entry).slogan) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-24 w-1/4 l:w-1/3" }, [
                    _c("p", { staticClass: "text-color1 helvetica_neue-f3" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.typeText(entry).smallText) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "flex justify-content ml-7/24 l:mt-4 mt-8",
                      attrs: { href: entry.detailUrl },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: `${_vm.baseurl}assets/Icon-Pfeil_rechts-Menu.svg`,
                          width: "48px",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-4 self-center text-color1 helvetica_neue-bold-f3 whitespace-nowrap",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(entry.category) +
                              " entdecken\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "absolute top-[67%] left-[40%] z-50" }),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("event-notice", { attrs: { event: _vm.event } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }