var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-row h-screen" },
    [
      _c("main-sidebar-menu", {
        attrs: { enabled: _vm.enabled, navdata: _vm.navdata },
      }),
      _vm._v(" "),
      _c("main-side-bar-button", {
        staticClass: "absolute mr-auto",
        class: {
          "right-10 top-10": _vm.height === "top",
          "left-10 bottom-10": _vm.height === "bottom",
          "z-20":
            (_vm.enabled && _vm.height === "top") || _vm.height == "bottom",
        },
        attrs: { enabled: _vm.enabled, height: _vm.height },
        on: {
          changeState: function ($event) {
            _vm.enabled = !_vm.enabled
            _vm.$emit("disable")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }