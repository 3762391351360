var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen overflow-y-hidden" }, [
    !_vm.enabledLeft
      ? _c(
          "div",
          { staticClass: "z-20 left-10 absolute" },
          [_c("bissig-logo", {})],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-1/3 absolute" },
      [
        _c("sidebar", {
          attrs: { navdata: _vm.navdata, height: "bottom" },
          on: {
            disable: function ($event) {
              _vm.enabledLeft = !_vm.enabledLeft
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "absolute w-full h-full",
        class: {
          "z-20": _vm.enabledEventOverlay,
          "z-0": !_vm.enabledEventOverlay,
        },
      },
      [
        _c("home-carousel", {
          attrs: { entries: _vm.entries, type: _vm.type, event: _vm.event },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-1/3 ml-auto top-10" },
      [
        _c("sidebar", {
          attrs: { navdata: _vm.navdata, height: "top" },
          on: {
            disable: function ($event) {
              _vm.enabledRight = !_vm.enabledRight
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }