var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("a", { attrs: { href: _vm.entry.url } }, [
      _c("div", {
        staticClass: "bg-img__16-9",
        style: { backgroundImage: `url(${_vm.entry.image})` },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }