var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-row h-screen" },
    [
      _c("main-sidebar-menu", {
        attrs: { enabled: _vm.enabledMainMenu, navdata: _vm.navdata },
      }),
      _vm._v(" "),
      _vm.enabled
        ? _c(
            "div",
            {
              staticClass: "flex flex-col flex-wrap w-full",
              class: {
                "bg-colorKitchen": _vm.type === "kitchen",
                "bg-colorFurniture": _vm.type === "furniture",
              },
            },
            [
              _c(
                "div",
                { staticClass: "ml-auto mt-28 w-3/4" },
                [
                  _c("filter-category", {
                    attrs: {
                      checkedFilter: _vm.checkedFilter,
                      filters: _vm.filters,
                      height: _vm.height,
                      countEntries: _vm.countEntries,
                      type: _vm.type,
                    },
                    on: {
                      checked: (n) => {
                        _vm.$emit("checked", n)
                      },
                      filter: function ($event) {
                        _vm.$emit("filter")
                        _vm.enabled = !_vm.enabled
                        _vm.$emit("disable")
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed z-20",
          class: {
            "right-10 top-10": _vm.height === "top",
            "left-10 bottom-10": _vm.height === "bottom",
          },
        },
        [
          _vm.enabled
            ? _c("div", { staticClass: "flex flex-row space-x-3" }, [
                _vm.height === "bottom"
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "border-color1 h-12 w-12 flex justify-center items-center",
                        on: {
                          click: function ($event) {
                            _vm.enabled = !_vm.enabled
                            _vm.$emit("disable")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: `${_vm.baseurl}assets/Icon-X-Close-Weiss.svg`,
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "border-[3px] border-color1 h-12",
                    on: {
                      click: function ($event) {
                        _vm.$emit("clearFilter")
                        _vm.$emit("disable")
                        _vm.enabled = !_vm.enabled
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "next_button" }, [
                      _vm._v("Filter zurücksetzen"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.height === "top"
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "border-[0.063rem] border-color1 h-12 w-12 flex justify-center items-center",
                        on: {
                          click: function ($event) {
                            _vm.enabled = !_vm.enabled
                            _vm.$emit("disable")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: `${_vm.baseurl}assets/Icon-X-Close-Weiss.svg`,
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.enabled
            ? _c(
                "div",
                { staticClass: "flex flex-row space-x-3 justify-center" },
                [
                  !_vm.enabledMainMenu
                    ? _c(
                        "div",
                        { staticClass: "space-x-3" },
                        [
                          _c("back-button", { attrs: { type: _vm.type } }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.enabled = !_vm.enabled
                                  _vm.$emit("disable")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: `${_vm.baseurl}assets/Icon-Filter-${_vm.type}.svg`,
                                  width: "48px",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("main-side-bar-button", {
                    attrs: {
                      enabled: _vm.enabledMainMenu,
                      height: _vm.height,
                      type: _vm.type,
                    },
                    on: {
                      changeState: function ($event) {
                        _vm.enabledMainMenu = !_vm.enabledMainMenu
                        _vm.$emit("disable")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }