var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      !_vm.enabled
        ? _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("changeState")
                },
              },
            },
            [
              _vm.type != null
                ? _c("img", {
                    attrs: {
                      src: `${_vm.baseurl}assets/Icon-Menu-${_vm.type}.svg`,
                      width: "48px",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: `${_vm.baseurl}assets/Icon-Menu-Grau.svg`,
                      width: "48px",
                    },
                  }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.enabled
        ? _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("changeState")
                },
              },
            },
            [
              _vm.height === "bottom"
                ? _c("img", {
                    attrs: {
                      src: `${_vm.baseurl}assets/Icon-Pfeil_links-Menu.svg`,
                      width: "48px",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: `${_vm.baseurl}assets/Icon-Pfeil_rechts-Menu.svg`,
                      width: "48px",
                    },
                  }),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }