var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "filters",
        class: _vm.height == "bottom" ? "ml-[0.7rem]" : "",
      },
      _vm._l(_vm.filters, function (item, index) {
        return _c("sub-filters", {
          key: index,
          attrs: {
            checkedFilter: _vm.checkedFilter,
            filter: item,
            type: _vm.type,
          },
          on: {
            checked: (n) => {
              _vm.$emit("checked", n)
            },
          },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "w-3/4 pr-4" }, [
      _c(
        "button",
        {
          staticClass: "w-full border-[3.5px] border-color1 p-2",
          on: {
            click: function ($event) {
              return _vm.$emit("filter")
            },
          },
        },
        [
          _c("p", { staticClass: "next_button" }, [
            _vm._v("Filter Anwenden (" + _vm._s(_vm.countEntries) + ")"),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }